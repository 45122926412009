import React, { useState, useEffect } from "react";
import { Icon, Image, Divider, Header } from 'semantic-ui-react'
import image1 from "../../../assets/locations/botuvera.jpg"
const Point19 = () => {
    return (
    <React.Fragment>
      <p>
      Џон Кузбак је објаснио на који начин промене сунчевог зрачења изазване променама Земљине орбите узрокују променљивост монсуна дуге десетине хиљада година. Кад летње осунчавање расте, углавном због Миланковићевог прецесионог циклуса, расте и температурни градијент на додиру копна и мора, што опет покреће монсунско струјање у суптропским областима. Тако, према овој тзв. „орбитално монсунској хипотези“, јаче летње осунчавање бива праћено влажнијим монсунима.
Проучавањем сталагмита из пећине Ботувера у Бразилу, утврђена је изванредна повезаност осунчавања и монсунске променљивости. Још један налаз из исте пећине показао је да су записи о климатској прошлости поновљиви и поуздани. Временски низови очувани у та два сталагмита протезали су се на последњих 116 000 година, а крива промене кисеоникових изотопа у њима пратила је синусоидну криву летњег осунчавања јужне Земљине полулопте.
Али најдужи и можда најупечатљивији докази о повезаности монсуна и осунчавања долазе из Кине. Запис о монсунској активности начињен је проучавањем бројних сталагмита (Cheng et al., 2016), и прати криву осунчавања 640 000 година уназад.
      </p>
      <Divider horizontal>
        <Header as="h4">
        <Icon name='image' />
        Фотографијa
        </Header>      
      </Divider>
      <Image src={image1} alt="PEĆINA BOTUVERA, BRAZIL" fluid />
      

    </React.Fragment>
    )
  }

  export default Point19;

