import React, { useState, useEffect } from "react";
import { Icon, Image, Divider, Header } from 'semantic-ui-react'
import image1 from "../../../assets/locations/sejseli.jpg"
const Point20 = () => {
    return (
    <React.Fragment>
      <p>
      У Миланковићево време се сумњало у утицај његових циклуса на периодичност климатских промена. Међутим, од 1970. различита проучавања су подржала модел и предвиђања српског научника, и показала да су и геолошки подаци откривени у  древним слојевима и фосилни остаци одређених организама компатибилни с променама климе које могу објаснити периодичност залеђивања сваких 100.000 година. Једна недавна студија је потврдила запис о климатским циклусима у окамењеним седиментима све до пре 215 милиона година, тј. до освита ере диносаура. И људи би, на неки начин, могли да буду Миланковићева деца. Недавна проучавања су довела до претпоставке да су климатске промене могле изазвати смену сушних периода и поплава у источној Африци, што је довело, пре 1,9 милиона година, до појаве Homo erectusа, првог хуманоида са великим мозгом. Према другим истраживањима, ти циклуси су такође покренули и миграције Homo sapiensa ван Африке – путовање које нас је повело кроз простор и време до ритма Миланковићевих циклуса.
      </p>
      <Divider horizontal>
        <Header as="h4">
        <Icon name='image' />
        Фотографијa
        </Header>      
      </Divider>
      <Image src={image1} alt="ISTOČNA AFRIKA" fluid />
      

    </React.Fragment>
    )
  }

  export default Point20;

