import React, { useState, useEffect } from "react";
import { Icon, Image, Divider, Header } from 'semantic-ui-react'
import image1 from "../../../assets/locations/sahara.jpg"
const Point25 = () => {
    return (
    <React.Fragment>
      <p>
      Северноафрички климатски циклуси имају јединствену историју која се може пратити милионима година уназад. Циклични климатски образац Сахаре одликује се значајним променама јачине северноафричких монсуна. Када је северноафрички монсун најјачи, годишње падавине расту, услед чега у области Сахаре буја вегетација, што ствара услове који се уобичајено називају "зелена Сахара". Обрнуто, када је северноафрички монсун релативно слаб, годишње количине падавина се смањују, вегетације је мање, и то сахарску климу приводи у фазу познату као "пустињска Сахара". 
Варијације климе у области Сахаре могу се у основи приписати променама у осунчавању услед спорих промена Земљиних орбиталних параметара. По Миланковићевој теорији, ти параметри су: прецесија равнодневице, промена нагиба осе, и ексцентричност. Прецесија равнодневице се сматра најзначајнијим орбиталним параметром за формирање циклуса "зелене Сахаре" и "пустињске Сахаре".
      </p>
      <Divider horizontal>
        <Header as="h4">
        <Icon name='image' />
        Фотографијa
        </Header>      
      </Divider>
      <Image src={image1} alt="SAHARA" fluid />
      

    </React.Fragment>
    )
  }

  export default Point25;

