import React, { useState, useEffect } from "react";
import { Icon, Image, Divider, Header } from 'semantic-ui-react'
import image1 from "../../../assets/locations/newark-basin.jpg"
const Point17 = () => {
    return (
    <React.Fragment>
      <p>
      Геолози су претпостављали да узорци пронађени у Њуаршком басену, древном рифтном басену који покрива северни Њу Џерси, југоисточну Пенсилванију и јужни Њујорк, можда одражавају климатске ефекте предвиђеног Миланковићевог циклуса дугог 405 000 година, али се старост седимената из овог басена до сада није могла довољно прецизно одредити да би се та повезаност могла доказати. Научници из Ламонт-Доерти опсерваторије Колумбија универзитета успели су да датују њуаршке стене упоређујући трагове обртања магнетних полова са истим траговима у прецизно датованим језгрима бушотина извађених у Националном парку Камена шума у Аризони.
Добро очувана секвенца језерских седимената из тријаса која садржи трагове климатских промена у Њуаршком басену потврђује постојање Миланковићевог циклуса дужине 405 000 година – периодичну промену облика Земљине орбите изазвану Земљином гравитационом интеракцијом са Јупитером и Венером.  Други Миланковићеви циклуси – цилклус прецесије, дуг 23 000 година, циклус промене нагиба осе, дуг 41 000 година, и 100 000 година дуг циклус повезан са ексцентричношћу орбите су релативно добро одражени и потврђени у геолошким и седиментним записима.
      </p>
      <Divider horizontal>
        <Header as="h4">
        <Icon name='image' />
        Фотографијa
        </Header>      
      </Divider>
      <Image src={image1} alt="Њуаршки басен, САД" fluid />
      

    </React.Fragment>
    )
  }

  export default Point17;

