import React, { useState, useEffect } from "react";
import { Icon, Image, Divider, Header } from 'semantic-ui-react'
import image1 from "../../../assets/locations/baoji.jpg"
const Point21 = () => {
    return (
    <React.Fragment>
      <p>
      Анализирана је величина зрнаца леса на Баођи профилу у интервалима од 10 cm. Промена величине лесних честица тумачи се као резултат промена у интензитету зимских монсунских ветрова из Сибира изазваних цикличним изменама ледених и међуледених периода. Подаци о величини честица су послужили као локални показатељ струјања зимских монсуна, и упоређени су са Миланковићевим орбиталним варијацијама које су Берже и Лутр израчунали 1991. године, уз контролу путем података о обртању магнетних полова.  Утврђено је следеће: (1) компоненте прецесије и нутације издвојене из података о величини честица на орбиталној временској скали веома добро се слажу са теоријским орбиталним подацима (2) постоји блиска кохерентност између временске скале одређене величином Баођи честица и орбиталних варијација током читавог периода од 0-2,5 милиона година. 
      </p>
      <Divider horizontal>
        <Header as="h4">
        <Icon name='image' />
        Фотографије
        </Header>      
      </Divider>
      <Image src={image1} alt="BAOJI LESNI PROFIL, KINA" fluid />
      

    </React.Fragment>
    )
  }

  export default Point21;

