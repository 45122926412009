import React, { useState, useEffect } from "react";
import { Icon, Image, Divider, Header } from 'semantic-ui-react'
import image1 from "../../../assets/locations/atol-einwetok.jpg"
const Point14 = () => {
    return (
    <React.Fragment>
      <p>
      Крива климатских промена одражених у промени морског нивоа може се генерисати из криве летњег осунчавања северне хемисфере уколико се претпостави да (1) ледници (на промену осунчавања) одговарају и директно и индиректно, као и да (2) постоји кашњење ледничког одговора на промене осунчавања... Даље потврде астрономске теорије се добијају уколико се исте те протпоставке користе за исцртавање криве очекиваних промена у запремини леда за последњих 180 000 година. Предвиђени минимуми глацијације (максимуми морског нивоа) у том интервалу падају на 120 000 и 80 000 година... Скорашњи резултати добијени Тх230/У234 методом датовања морских карбоната јасно указују да је у целом свету до значајног повишења мнорског нивоа у односу на данашњи дошло пре око 120 000 година. Thruber et.al. су утврдили тај догађај на атолу Ејнветок, као и Stearns и Thruber у Медитерану; Broecker и Thruber на Florida Keys; Broecker и Kaufman у јужној Калифорнији; и Veeh на острвима Тихог и Индијског океана.


      </p>
      <Divider horizontal>
        <Header as="h4">
        <Icon name='image' />
        Фотографијa
        </Header>      
      </Divider>
      <Image src={image1} alt="АТОЛ ЕЈНВЕТОК, ТИХИ ОКЕАН" fluid />
      

    </React.Fragment>
    )
  }

  export default Point14;

