import React, { useState, useEffect } from "react";
import { Icon, Image, Divider, Header } from 'semantic-ui-react'
import image1 from "../../../assets/locations/sanbao.jpg"
const Point27 = () => {
    return (
    <React.Fragment>
      <p>
      Подаци добијени анализом кисеоникових изотопа у узорцима из кинеских поћина описују промене које су се одигравале и у азијском монсуну и у глобалној клими. Они које смо добили из Санбао пећине омогућили су нам да запис о клими Кине продужимо на последњих 640 000 година. Дужина тог записа и прецизно одређивање старости узорака омогућују нам да тестирамо идеју да су промене у осунчавању изазване Земљином прецесијом довеле до окончања сваког од последњих седам ледених доба, као и хиљадама година дугих интервала смањених монсунских киша који су повезани са сваким од завршетака ледених доба. Окончања ледених доба раздвојена су са по четири или пет прецесионих циклуса, што подржава идеју о томе да је циклус глацијације од 100 000 година заправо средња вредност дискретних бројева прецесионих циклуса. Ова посматрања указују на то да  Миланковићева динамика осунчавања, делимично, диктира ритам појављивања хиљадугодишњих збивања, укључујући она која су повезана са окончањима ледених доба, а исто тако и са „незавршеним окончањима“.  
      </p>
      <Divider horizontal>
        <Header as="h4">
        <Icon name='image' />
        Фотографијa
        </Header>      
      </Divider>
      <Image src={image1} alt="PEĆINA SANBAO, KINA" fluid />
      

    </React.Fragment>
    )
  }

  export default Point27;

